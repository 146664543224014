import React, { useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

interface Visit {
  id: string;
  visit_date: string;
  purpose: string;
  outcomes: string;
}

export const VcaVisits: React.FC = () => {
   const location = useLocation();
   const vcaId = location.state?.vca.unique_id;
    const [visits, setVisits] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await axios.get(
          `https://server.achieve-dqa.bluecodeltd.com/child/vca-visits/{vcaId}`
        );
        setVisits(response.data.data);
        console.log(" VCA visits::", response.data.data)
      } catch (error) {
        console.error('Error fetching VCA visits:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisits();
  }, [vcaId]);

  const columns = [
    {
      title: 'Problem Number',
      dataIndex: 'problem_number',
      key: 'problem_number',
    },
    {
      title: 'Visit Date',
      dataIndex: 'visit_date',
      key: 'visit_date',
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: 'Outcome',
      dataIndex: 'outcome',
      key: 'outcome',
    },
    {
      title: 'Last Interacted With',
      dataIndex: 'last_interacted_with',
      key: 'last_interacted_with',
    },
  ];  

  if (loading) {
    return <Skeleton active />;
  }

  return (
      <Table
        columns={columns}
        dataSource={visits}
        scroll={{x:400}}
        rowKey="visit_id"
        pagination={{ pageSize: 5 }}
      />
    );
};

export default VcaVisits;