import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getTrendingActivities, TrendingActivity } from '@app/api/activity.api';
import { Button, Skeleton, Typography, Tabs, notification, Modal } from 'antd';
import axios from 'axios';
import MainDashboardPage from './MainDashboardPage';
import { HintText } from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { useNavigate } from 'react-router-dom';
import { persistUser } from '@app/services/localStorage.service';



const { TabPane } = Tabs;

interface User {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  location: string;
  tags: string[];
}

export const MedicalDashboardPage: React.FC = () => {
  
  const [trending, setTrending] = useState<TrendingActivity[]>([]);
  const { t } = useTranslation();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/me`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
        const userData = response.data.data;
        setUser(userData);
        persistUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchTrendingActivities = async () => {
      try {
        const res = await getTrendingActivities();
        setTrending(res);
      } catch (error) {
        console.error('Error fetching trending activities:', error);
      }
    };

    fetchTrendingActivities();
  }, []);

  const userHasAccess = (requiredRoles: string[]) => {
    return user?.tags?.some((tag) => requiredRoles.includes(tag));
  };
 
  return (
    <>
      <div style={{ padding: '25px 50px'}}>
        <Typography>
          Hi there 👋 {user?.first_name}, <br /> Welcome back!
        </Typography>
        <Typography.Title level={4}>
          {loading ? <Skeleton active paragraph={{ rows: 1 }} /> : `${user?.location} District`}
        </Typography.Title>
        <HintText>Choose an option to get started</HintText>
        <Tabs defaultActiveKey="1">
          {userHasAccess(['Administrator', 'Provincial User', 'Headquarter User']) && (
            <>
              <TabPane tab="User Management Portal" key="1">
                <Typography>You have rights to the user management portal</Typography>
                <Button onClick={() => navigate('/users-management-portal')} type="primary">
                  Go to Users
                </Button>
              </TabPane>
              <TabPane tab="HQ/Provincial Feedback" key="2">
                <Typography>You have rights to view HQ and Province Supervisory Modules</Typography>
                <Button onClick={() => navigate('/hq-province-supervisory-tools-feedback-dashboard')} type="primary">
                  View Dashboards
                </Button>
              </TabPane>
            </>
          )}
          <TabPane tab="New Applications Updates" key="3">
            <Button type="primary" onClick = {() => navigate('/release-notes')}>
              View New Updates
            </Button>
          </TabPane>
        </Tabs>
      </div>
      <br />
      <MainDashboardPage />
    </>
  );
};

export default MedicalDashboardPage;