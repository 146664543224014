import React, { useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export const CaregiverVisits: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const householdId = location.state?.household.household_id;
  const [visits, setVisits] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await axios.get(
          `https://server.achieve-dqa.bluecodeltd.com/household/household-visits/${householdId}`
        );
        setVisits(response.data.data);
        console.log("caregiver visits::", response.data.data);
      } catch (error) {
        console.error('Error fetching caregiver visits:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisits();
  }, [householdId]);

  const columns = [
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('Activity'),
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: t('Outcome'),
      dataIndex: 'outcome',
      key: 'outcome',
    },
    {
      title: t('Last Interacted With'),
      dataIndex: 'last_interacted_with',
      key: 'last_interacted_with',
    },
  ];

  if (loading) {
    return <Skeleton active />;
  }
  return (
    <Table
      columns={columns}
      dataSource={visits}
      scroll={{ x: 400 }}
      rowKey="visit_id"
      pagination={{ pageSize: 5 }}
    />
  );
};

export default CaregiverVisits;
