import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import CaregiverProfileLayout from '@app/components/profile/CaregiverProfileLayout';
import VcaProfileLayout from '@app/components/profile/VcaProfileLayout';
import MemberProfileLayout from '@app/components/profile/MemberProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';


const HouseholdsPage = React.lazy(() => import('@app/pages/HouseholdsRegisterPage'));
const UnknownCasesRegisterPage = React.lazy(() => import('@app/pages/UnknownCasesRegisterPage'));
const LowRegisterPage = React.lazy(() => import('@app/pages/LowCasesRegisterPage'));
const MediumCasesRegisterPage = React.lazy(() => import('@app/pages/MediumCasesRegisterPage'));
const HighCasesRegisterPage = React.lazy(() => import('@app/pages/HighCasesRegisterPage'));

const DTPMTDashboard = React.lazy(() => import('@app/pages/DTPMTPage'));
const CDAMentorshipPage = React.lazy(() => import('@app/pages/CDAMentorshipPage'));
const CWACMentorshipPage = React.lazy(() => import('@app/pages/CWACMentorshipPage'));
const DepartmentSelectDashboardPage = React.lazy(() => import('@app/pages/DepartmentSelectDashboardPage'));

const CommunityCaseManagementPage = React.lazy(() => import('@app/pages/DashboardPages/CommunityCaseManagementDashboardPage'));

const VcasPage = React.lazy(() => import('@app/pages/VcasRegisterPage'));
const UserManagementPage = React.lazy(() => import('@app/pages/UserManagementPage'));

const CaseManagementDistrictFeedBackDashboardPage = React.lazy(() => import('@app/pages/CaseManagementDistrictFeedBackDashboardPage'));
const FinanceDistrictFeedBackDashboardPage = React.lazy(() => import('@app/pages/FinanceDistrictFeedBackDashboardPage'));
const StoresDistrictFeedBackDashboardPage = React.lazy(() => import('@app/pages/StoresDistrictFeedBackDashboardPage'));
const ProcurementDistrictFeedBackDashboardPage = React.lazy(() => import('@app/pages/ProcurementDistrictFeedBackDashboardPage'));
const HumanResourceDistrictFeedBackDashboardPage = React.lazy(() => import('@app/pages/HumanResourceDistrictFeedBackDashboardPage'));

const HQProvinceSupervisoryToolsFeedBackPage = React.lazy(() => import('@app/pages/HQProvinceSupervisoryToolsFeedBackPage'));
const HQProvinceSupervisoryToolsDashboardPage = React.lazy(() => import('@app/pages/HQFeedBackDashboardPage'));

const LusakaProvinceFeedbackPage = React.lazy(() => import('@app/pages/LusakaProvinceFeedBackDashboardPage'));
const CentralProvinceFeedbackPage = React.lazy(() => import('@app/pages/CentralProvinceFeedBackDashboardPage'));
const CopperbeltProvinceFeedbackPage = React.lazy(() => import('@app/pages/CopperbeltProvinceFeedBackDashboardPage'));
const EasternProvinceFeedbackPage = React.lazy(() => import('@app/pages/EasternProvinceFeedBackDashboardPage'))
const LuapulaProvinceFeedbackPage = React.lazy(() => import('@app/pages/LuapulaProvinceFeedBackDashboardPage'));
const MuchingaProvinceFeedbackPage = React.lazy(() => import('@app/pages/MuchingaProvinceFeedBackDashboardPage'));
const NorthernProvinceFeedbackPage = React.lazy(() => import('@app/pages/NorthernProvinceFeedBackDashboardPage'));
const NorthWesternProvinceFeedbackPage = React.lazy(() => import('@app/pages/NorthWesternProvinceFeedBackDashboardPage'));
const SouthernProvinceFeedbackPage = React.lazy(() => import('@app/pages/SouthernProvinceFeedBackDashboardPage'));
const WesternProvinceFeedbackPage = React.lazy(() => import('@app/pages/WesternProvinceFeedBackDashboardPage'));

const CommunityDevSelfAssessmentPage = React.lazy(() => import('@app/pages/CommunityDevSelfAssessmentPage'));
const SocialWelfareSelfAssessmentPage = React.lazy(() => import('@app/pages/SocialWelfareSelfAssessmentPage'));
const MembersPage = React.lazy(() => import('@app/pages/MembersRegisterPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const StoresAdvancedFormPage = React.lazy(() => import('@app/pages/StoresDepartmentPage'));
const ProcurementAdvancedFormPage = React.lazy(() => import('@app/pages/ProcurementDepartmentPage'));
const HumanResourceAdvancedFormsPage = React.lazy(() => import('@app/pages/HumanResourceDepartmentPage'));
const FinanceAdvancedFormsPage = React.lazy(() => import('@app/pages/FinanceDepartmentPage'));

const CaregiverPersonalInfoPage = React.lazy(() => import('@app/pages/CaregiverPersonalInfoPage'));
const VcaPersonalInfoPage = React.lazy(() => import('@app/pages/VcaPersonalInfoPage'));
const MemberPersonalInfoPage = React.lazy(() => import('@app/pages/MemberPersonalInfoPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/supervisory-tools-dashboard';

const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const HouseholdsRegister = withLoading(HouseholdsPage);

const UnknownCasesRegister = withLoading(UnknownCasesRegisterPage);
const LowRegister = withLoading(LowRegisterPage);
const MediumCasesRegister = withLoading(MediumCasesRegisterPage);
const HighCasesRegister = withLoading(HighCasesRegisterPage);
const DTPMTDashboardPage = withLoading(DTPMTDashboard);

const CDAPage = withLoading(CDAMentorshipPage);
const CWACPage = withLoading(CWACMentorshipPage);

const DepartmentSelectionDashboardPage = withLoading(DepartmentSelectDashboardPage);

const CommunityCaseManagement = withLoading(CommunityCaseManagementPage);

const VcasRegister = withLoading(VcasPage);
const MembersRegister = withLoading(MembersPage);
const UsersPortal = withLoading(UserManagementPage);

const CaseManagementDistrictFeedBackDashboard = withLoading(CaseManagementDistrictFeedBackDashboardPage)
const FinanceDistrictFeedBackDashboard = withLoading(FinanceDistrictFeedBackDashboardPage)
const StoresDistrictFeedBackDashboard = withLoading(StoresDistrictFeedBackDashboardPage)
const ProcurementDistrictFeedBackDashboard = withLoading(ProcurementDistrictFeedBackDashboardPage)
const HumanResourceDistrictFeedBackDashboard = withLoading(HumanResourceDistrictFeedBackDashboardPage)

const HQProvinceSupervisoryToolsFeedBackDashboard = withLoading(HQProvinceSupervisoryToolsFeedBackPage)
const HQProvinceSupervisoryToolsDashboardMainPage = withLoading(HQProvinceSupervisoryToolsDashboardPage)

const LusakaProvinceFeedBackDashboard = withLoading(LusakaProvinceFeedbackPage)
const CentralProvinceFeedBackDashboard = withLoading(CentralProvinceFeedbackPage)
const CopperbeltProvinceFeedBackDashboard = withLoading(CopperbeltProvinceFeedbackPage)
const EasternProvinceFeedBackDashboard = withLoading(EasternProvinceFeedbackPage)
const LuapulaProvinceFeedBackDashboard = withLoading(LuapulaProvinceFeedbackPage)
const MuchingaProvinceFeedBackDashboard = withLoading(MuchingaProvinceFeedbackPage)
const NorthernProvinceFeedBackDashboard = withLoading(NorthernProvinceFeedbackPage)
const NorthWesternProvinceFeedBackDashboard = withLoading(NorthWesternProvinceFeedbackPage)
const SouthernProvinceFeedBackDashboard = withLoading(SouthernProvinceFeedbackPage)
const WesternProvinceFeedBackDashboard = withLoading(WesternProvinceFeedbackPage)

const CommunityDevSelfAssessmentDashboardPage = withLoading(CommunityDevSelfAssessmentPage)
const SocialWelfareSelfAssessmentDashboardPage = withLoading(SocialWelfareSelfAssessmentPage)

const AdvancedForm = withLoading(AdvancedFormsPage);
const StoresAdvancedForm = withLoading(StoresAdvancedFormPage);
const FinanceAdvancedForm = withLoading(FinanceAdvancedFormsPage);
const ProcurementAdvancedForm = withLoading(ProcurementAdvancedFormPage);
const HumanResourceAdvancedForm = withLoading(HumanResourceAdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const CaregiverPersonalInfo = withLoading(CaregiverPersonalInfoPage);
const VcaPersonalInfo = withLoading(VcaPersonalInfoPage);
const MemberPersonalInfo = withLoading(MemberPersonalInfoPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<NftDashboard />} />
          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />

          <Route path="/community-development-self-assessment-dashboard" element={<CommunityDevSelfAssessmentDashboardPage />} />
          <Route path="/social-welfare-district-self-assessment-dashboard" element={<SocialWelfareSelfAssessmentDashboardPage />} />
          <Route path="/supervisory-tools-department-selection" element={<DepartmentSelectionDashboardPage />} />

          <Route path="/case-management-feedback-district-dashboard" element={<CaseManagementDistrictFeedBackDashboard />} />
          <Route path="/finance-feedback-district-dashboard" element={<FinanceDistrictFeedBackDashboard />} />
          <Route path="/stores-feedback-district-dashboard" element={<StoresDistrictFeedBackDashboard />} />
          <Route path="/procurement-feedback-district-dashboard" element={<ProcurementDistrictFeedBackDashboard />} />
          <Route path="/human-resource-feedback-district-dashboard" element={<HumanResourceDistrictFeedBackDashboard />} />

          <Route path="/hq-province-supervisory-tools-feedback-dashboard" element={<HQProvinceSupervisoryToolsFeedBackDashboard />} />
          <Route path="/hq-province-supervisory-tools-feedback-main-dashboard" element={<HQProvinceSupervisoryToolsDashboardMainPage />} />

          <Route path="/lusaka-province-dashboard" element={<LusakaProvinceFeedBackDashboard />} />
          <Route path="/central-province-dashboard" element={<CentralProvinceFeedBackDashboard />} />
          <Route path="/copperbelt-province-dashboard" element={<CopperbeltProvinceFeedBackDashboard />} />
          <Route path="/eastern-province-dashboard" element={<EasternProvinceFeedBackDashboard />} />
          <Route path="/luapula-province-dashboard" element={<LuapulaProvinceFeedBackDashboard />} />
          <Route path="/muchinga-province-dashboard" element={<MuchingaProvinceFeedBackDashboard />} />
          <Route path="/northern-province-dashboard" element={<NorthernProvinceFeedBackDashboard />} />
          <Route path="/north-western-province-dashboard" element={<NorthWesternProvinceFeedBackDashboard />} />
          <Route path="/southern-province-dashboard" element={<SouthernProvinceFeedBackDashboard />} />
          <Route path="/western-province-dashboard" element={<WesternProvinceFeedBackDashboard />} />

          <Route path="/users-management-portal" element={<UsersPortal />} />
          <Route path="/supervisory-modules-dashboard" element={<DTPMTDashboardPage />} />

          <Route path="/unknown-cases" element={<UnknownCasesRegister />} />
          <Route path="/low-risk-cases" element={<LowRegister />} />
          <Route path="/medium-risk-cases" element={<MediumCasesRegister />} />
          <Route path="/high-risk-cases" element={<HighCasesRegister />} />

          <Route path="/cda-supervision-and-mentorship-tool" element={<CDAPage />} />
          <Route path="/cwac-supervision-and-mentorship-tool" element={<CWACPage />} />

          <Route path="/community-case-management" element={< CommunityCaseManagement />} />

          <Route path="apps">
            <Route path="households-register" element={<HouseholdsRegister />} />
            <Route path="vcas-register" element={<VcasRegister />} />
            <Route path="households-members-register" element={<MembersRegister />} />

          </Route>
          <Route path="forms">
            <Route path="case-management-district-self-assessment" element={<AdvancedForm />} />
            <Route path="stores-district-self-assessment" element={<StoresAdvancedForm />} />
            <Route path="procurement-district-self-assessment" element={<ProcurementAdvancedForm />} />
            <Route path="human-resource-district-self-assessment" element={<HumanResourceAdvancedForm />} />
            <Route path="finance-district-self-assessment" element={<FinanceAdvancedForm />} />
          </Route>
          <Route path="data-tables" element={<DataTables />} />
          <Route path="charts" element={<Charts />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />

          <Route path="profile" element={<CaregiverProfileLayout />}>
            <Route path="household-profile/:household_id" element={<CaregiverPersonalInfo />} />
          </Route>

          <Route path="profile" element={<VcaProfileLayout />}>
            <Route path="vca-profile/:household_id" element={<VcaPersonalInfo />} />
          </Route>

          <Route path="profile" element={<MemberProfileLayout />}>
            <Route path="member-profile/:household_id" element={<MemberPersonalInfo />} />
          </Route>

          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};