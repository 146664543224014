import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getTrendingActivities, TrendingActivity } from '@app/api/activity.api';
import { Button, Skeleton, Typography, Tabs, notification, Modal } from 'antd';
import axios from 'axios';
import MainDashboardPage from './MainDashboardPage';
import { HintText } from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { useNavigate } from 'react-router-dom';
import { persistUser } from '@app/services/localStorage.service';

const { TabPane } = Tabs;

interface User {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  location: string;
  tags: string[];
}

export const MedicalDashboardPage: React.FC = () => {
  const [trending, setTrending] = useState<TrendingActivity[]>([]);
  const { t } = useTranslation();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [notificationShown, setNotificationShown] = useState(false);
  const navigate = useNavigate();
  const notificationRef = useRef<string | null>(null);

  const [isChangelogModalVisible, setIsChangelogModalVisible] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/me`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
        const userData = response.data.data;
        setUser(userData);
        persistUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchTrendingActivities = async () => {
      try {
        const res = await getTrendingActivities();
        setTrending(res);
      } catch (error) {
        console.error('Error fetching trending activities:', error);
      }
    };

    fetchTrendingActivities();
  }, []);

  useEffect(() => {
    if (user && !notificationShown) {
      const key = 'user-management-notification';
      notification.open({
        key,
        message: 'New Updates!',
        description: (
          <>
            What's new in this version?
            <br />
            <br />
            <Button onClick={showChangelogModal} type="primary">
              View Updates
            </Button>
          </>
        ),
        placement: 'topRight',
        duration: null,
        style: { width: 300, margin: '16px' },
      });
      notificationRef.current = key;
      setNotificationShown(true);

      setTimeout(() => {
        if (notificationRef.current) {
          notification.close(notificationRef.current);
          notificationRef.current = null;
        }
      }, 5000);
    }
  }, [navigate, notificationShown, user]);

  const showChangelogModal = () => {
    setIsChangelogModalVisible(true);
  };

  const handleChangelogModalClose = () => {
    setIsChangelogModalVisible(false);
  };

  const userHasAccess = (requiredRoles: string[]) => {
    return user?.tags?.some((tag) => requiredRoles.includes(tag));
  };

  return (
    <>
      <div style={{ padding: '25px 50px' }}>
        <Typography>
          Hi there 👋 {user?.first_name}, <br /> Welcome back!
        </Typography>
        <Typography.Title level={4}>
          {loading ? <Skeleton active paragraph={{ rows: 1 }} /> : `${user?.location} District`}
        </Typography.Title>
        <HintText>Choose an option to get started</HintText>
        <Tabs defaultActiveKey="1">
          {userHasAccess(['Administrator', 'Provincial User', 'Headquarter User']) && (
            <>
              <TabPane tab="User Management Portal" key="1">
                <Typography>You have rights to the user management portal</Typography>
                <Button onClick={() => navigate('/users-management-portal')} type="primary">
                  Go to Users
                </Button>
              </TabPane>
              <TabPane tab="HQ/Provincial Feedback" key="2">
                <Typography>You have rights to view HQ and Province Supervisory Modules</Typography>
                <Button onClick={() => navigate('/hq-province-supervisory-tools-feedback-dashboard')} type="primary">
                  View Dashboards
                </Button>
              </TabPane>
            </>
          )}
          <TabPane tab="New Applications Updates" key="3">
            <Button type="primary" onClick={showChangelogModal}>
              View New Updates
            </Button>
          </TabPane>
        </Tabs>
      </div>
      <br />
      <Modal
        title="Changelog"
        open={isChangelogModalVisible}
        onCancel={handleChangelogModalClose}
        footer={[
          <Button key="close" onClick={handleChangelogModalClose}>
            Close
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          <Typography.Title level={5}>Release Notes</Typography.Title>
          <Typography.Text>
            Version: 1.5.2
            <br />
            Release Date: 6th December 2024
          </Typography.Text>
          <Typography.Title level={5}>New Features on the Community Case Management Module</Typography.Title>
          <ul>
            <li><b>Risk Level Filtering Implemented:</b>
              <ul>
                <ul>
                  <li><b>High Cases Register:</b> Shows only cases that are considered high risk. Access to these cases is limited based on your user role to ensure sensitive information is protected.</li>
                  <li><b>Medium Cases Register:</b> Displays cases that are of medium risk.</li>
                  <li><b>Low Cases Register:</b> Lists cases with low risk.</li>
                  <li><b>Unknown Cases Register:</b> Includes cases where the risk level is not known.</li>
                </ul>
              </ul>
            </li>
          </ul>
        </Typography.Paragraph>
      </Modal>
      <MainDashboardPage />
    </>
  );
};

export default MedicalDashboardPage;