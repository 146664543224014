import React from 'react';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

function ReleaseNotePage() {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ margin: '20px', padding: '25px 50px', fontFamily: '"Times New Roman", Times, serif' }}>
        <Typography.Paragraph>
          <PageTitle>Release Notes</PageTitle>
          <Typography.Title level={3} style={{ fontFamily: '"Times New Roman", Times, serif' }}>Release Notes</Typography.Title>

          <Typography.Text>
            <b style={{ fontSize: '20px' }}>Program Management Platform</b>
            <br />
            Version: 1.0v <i>Initial Release</i>
            <br />
            Release Date: January 9, 2025
            <br />
          </Typography.Text>

          <Typography.Title level={3} style={{ fontFamily: '"Times New Roman", Times, serif' }}>General</Typography.Title>
          <ul>
            <li>
              <b>Redesigned User Interface:</b> The Program Management Platform's interface has been redesigned to enhance usability and display essential information directly on the homepage.
            </li>
            <li>
              <b>Integration of the District Technical Performance Monitoring Tool, and Dashboards:</b> The PMP has been combined with Supervisory Modules and dashboards. They now operate as a single application, eliminating the need for users to remember credentials for three separate platforms. Ministry staff can now access all necessary information from one platform.
            </li>
            <li>
              <b>Flagged Forms Page:</b> The Flagged Forms page has been enhanced to display flagged forms for each district and unique cases.
            </li>
            <li>
              <b>Cross-Platform Availability:</b> The application is cross-platform and can be installed on desktop, tablet, and mobile Android devices.
            </li>
          </ul>

          <Typography.Title level={3} style={{ fontFamily: '"Times New Roman", Times, serif' }}>1. Community Case Management Module</Typography.Title>
          <Typography.Title level={4}>November 2024 - Bug Fixes</Typography.Title>
          <ul>
            <li>The VCA table search now correctly displays results by disabling the spin loader.</li>
            <li>Admins can assign provincial users to multiple districts.</li>
            <li>The CWAC Name field now correctly displays the CWAC name, fixing the undefined issue.</li>
          </ul>

          <Typography.Title level={4}>December 2024</Typography.Title>
          <Typography.Title level={4}>Enhancements</Typography.Title>
          <ul>
            <li>
              <b>Dashboards, Charts, and Visualizations:</b> The PMP has been enhanced to integrate charts and visualizations for easy data interpretation.
            </li>
            <b>Case Classification Registers:</b>
            <li><b>Unknown Risk Cases Register:</b> Displays cases with unknown risk levels.</li>
            <li><b>Low-Risk Cases Register:</b> Displays cases with low-risk levels.</li>
            <li><b>Medium-Risk Register:</b> Displays cases with medium-risk levels.</li>
            <li><b>High-Risk Cases Register:</b> Displays cases with high-risk levels.</li>
            <li><b>Risk Level Filtering:</b> The pie chart on the dashboard now allows filtering by each risk level classification based on the user's selection: High, Medium, or Low Risks.</li>
            <li><b>District-Level Filters:</b> District users can now access data filtered by their district, with options to filter down to the ward and CWAC levels.</li>
            <li><b>Provincial-Level Filters:</b> Provincial users can view data from all districts within their province and filter down to the district level.</li>
            <li><b>HQ-Level Filters:</b> HQ users have broad access to view data from all provinces and districts, with filters available for district and province levels.</li>
          </ul>

          <Typography.Title level={4}>January 2025</Typography.Title>
          <Typography.Title level={4}>Enhancements</Typography.Title>
          <br />
          <b>VCA Profile Now Includes:</b>
          <ul>
            <li><b>VCA Case Plans and Services:</b> The table now displays the case plan and services for each case.</li>
            <li><b>VCA Referrals and Referral Feedback:</b> The table now displays referral details and feedback for each case.</li>
            <li><b>VCA Visit Activities:</b> The table now displays visit activities for each case.</li>
            <li><b>VCA Flag Record Form:</b> The form now displays the flag record for each case.</li>
            <br />
            <b>Caregiver Profile Now Includes:</b>
            <li><b>Caregiver Case Plans and Services:</b> The table now displays the case plan and services for each caregiver.</li>
            <li><b>Caregiver Referrals and Referral Feedback:</b> The table now displays referral details and feedback for each caregiver.</li>
            <li><b>Caregiver Visit Activities:</b> The table now displays visit activities for each caregiver.</li>
            <li><b>Caregiver Flag Record Form:</b> The form now displays the flag record for each caregiver.</li>
          </ul>
          <b>All Community Case Management Module Registers Now Includes:</b>
          <ul>
            <li>The risk level or case classification registers and the Households and VCAs register now have EXPORT to CSV capability.</li>
          </ul>

          <Typography.Title level={3} style={{ fontFamily: '"Times New Roman", Times, serif' }}>2. Supervisory Tools Module</Typography.Title>
          <Typography.Title level={4}>July - August 2024</Typography.Title>
          <ul>
            <li>
              <b>Supervisory and Performance Modules:</b> Users can access and manage various supervisory modules, including Case Management, Finance, Stores, Procurement, and Human Resources, with role-based permissions tied to their department in the Ministry of Community Development and Social Service.
            </li>
            <li>
              <b>Self-Assessments:</b> District users can submit self-assessments for their department, review summary assessments, and access Performance Improvement Plans for actionable insights.
            </li>
            <li>
              <b>Provincial Assessment Review:</b> Provincial and HQ users can assess self-assessments submitted by districts, offer feedback, and evaluate province-wide performance.
            </li>
            <li>
              <b>District Assessment Scoring:</b> Added fields to track Initial and Final Scores for the first and second assessments. The initial score is from the self-assessment at the district level, while the final score is provided by HQ and the province.
            </li>
            <li>
              <b>HQ Feedback:</b> Feedback is submitted through the Performance Improvement Plan form, where provincial and HQ users provide their details and role at the ministry.
            </li>
          </ul>

          <Typography.Title level={4}>September - October 2024 <br /> Bug Fixes</Typography.Title>
          <ul>
            <li>
              Correction of Final HQ/Province Score in Assessment Modal. The final HQ/Province score for the specified assessment was fixed to automatically update the assessment final score response.
              Issue: The score was not auto-updating in the modal assessment status for certain assessments.
            </li>
          </ul>

          <Typography.Title level={4}>November 2024 - <br /> Enhancements</Typography.Title>
          <ul>
            <li>Redesigned UI for District Users and HQ/Province Users supervisory tools dashboards.</li>
            <li>Added province-specific filters for all techinical areas dashboards.</li>
            <li>Added department-level filters for all technical areas dashboards.</li>
            <li>Added year filter for all technical areas dashboards.</li>
          </ul>

          <Typography.Title level={4}>Bug Fixes</Typography.Title>
          <ul>
            <li>The participant form now automatically includes the currently logged-in user, removing the need for manual entry.</li>
            <li>The final HQ/Province score in the Assessment Module now updates automatically when the status changes.</li>
            <li>Fixed an issue where form data was lost when navigating between steps. The form now maintains state, preventing data loss.</li>
            <li>HQ/Province feedback modules now correctly display district team responses and final responses independently.</li>
            <li>Assessments are now exportable in PDF format only.</li>
          </ul>

          <Typography.Title level={4}>January 2024 - <br /> Enhancements</Typography.Title>
          <ul>
            <li>Users can now export the entire assessment report.</li>
          </ul>

          <Typography.Title level={4}>Feedback and Support</Typography.Title>
          <ul>
            <li>For any questions or suggestions, feel free to reach out to us.</li>
          </ul>
        </Typography.Paragraph>
        <br />
        <Button type='primary' onClick={() => navigate('/')}>Back to Home</Button>
      </div>
    </>
  );
}

export default ReleaseNotePage;
