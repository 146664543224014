import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton, Typography, Alert, Table, Tag } from 'antd';
import axios from 'axios';
import styled from 'styled-components';

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  text-transform: capitalize;
`;

const columns = [
  {
    title: 'Referral Date',
    dataIndex: 'referral_date',
    key: 'referral_date',
    sorter: (a: any, b: any) => new Date(a.referral_date).getTime() - new Date(b.referral_date).getTime(),
    render: (text: string | null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Institution Referred',
    dataIndex: 'institution_referred',
    key: 'institution_referred',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Contact Person',
    dataIndex: 'contact_person',
    key: 'contact_person',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Date Referred',
    dataIndex: 'date_referred',
    key: 'date_referred',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Organisation',
    dataIndex: 'organisation',
    key: 'organisation',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Place',
    dataIndex: 'place',
    key: 'place',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Household Head',
    dataIndex: 'household_head',
    key: 'household_head',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Services Requested',
    dataIndex: 'services_requested',
    key: 'services_requested',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Services Already Provided',
    dataIndex: 'services_already_provided',
    key: 'services_already_provided',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Consent',
    dataIndex: 'consent',
    key: 'consent',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Person Referring',
    dataIndex: 'person_referring',
    key: 'person_referring',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Receiver Name',
    dataIndex: 'receiver_name',
    key: 'receiver_name',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Services Provided',
    dataIndex: 'services_provided',
    key: 'services_provided',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Last Interacted With',
    dataIndex: 'last_interacted_with',
    key: 'last_interacted_with',
    render: (text = null) => (text ? text : 'Not Applicable'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: [
      { text: 'Pending', value: 'Pending' },
      { text: 'Completed', value: 'Completed' },
    ],
    onFilter: (value: string, record: any) => record.status === value,
    render: (status: string) => (
      <Tag color={status === 'Completed' ? 'green' : 'orange'}>{status || 'Not Applicable'}</Tag>
    ),
  },
];

const cleanData = (data: any[]) => {
  return data.map((record) => {
    const cleanedRecord = { ...record };
    Object.keys(cleanedRecord).forEach((key) => {
      if (cleanedRecord[key] === null || cleanedRecord[key] === undefined) {
        cleanedRecord[key] = 'Not Applicable';
      }
    });
    return cleanedRecord;
  });
};

export const HouseholdReferralFeedback: React.FC = () => {
  const location = useLocation();
  const vcaId = location.state?.vca?.unique_id; // Get VCA unique ID from location state

  const [isLoading, setLoading] = useState(false);
  const [referralFeedback, setReferralFeedback] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (vcaId) {
      setLoading(true);
      axios
      
        .get(`https://server.achieve-dqa.bluecodeltd.com/household/household-referrals-feedback/${vcaId}`)
        .then((response) => {
          const data = cleanData(response.data.data); 
          setReferralFeedback(data);
          setLoading(false);
          
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [vcaId]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Skeleton active />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Alert
          message="We encountered an error fetching Household referral feedback records. Refresh the page to see if the issue persists."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <Wrapper>
      <Title>Household Referral Feedback</Title>
      <Table
        columns={columns}
        dataSource={referralFeedback}
        pagination={false}
        rowKey="referral_date"
        scroll={{ x: 1500 }}
      />
    </Wrapper>
  );
};
